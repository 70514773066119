import router from 'next/router';
import Loading from '@/components/common/loading/Loading';
import { GET_TOP_5_COUNTRIES, GET_TOP_COUNTRIES } from '@/graphql/disclosure/queries';
import { Top5CountriesQuery, TopCountriesQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
import { Alert, Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { CardHeader } from './CardHeader';
import { CardLayout } from './CardLayout';

type Props = { disclosureCycleId?: string | null };
export const CountryGraphsCard = ({ disclosureCycleId }: Props) => {
  const { authorityTypeId } = useStore();
  const { data, loading, error } = useQuery<Top5CountriesQuery>(GET_TOP_5_COUNTRIES, {
    variables: {
      disclosureCycleId,
      authorityTypeId,
    },
    fetchPolicy: 'network-only',
    client: disclosureClient,
    onError: onApolloError,
  });

  const handleClickButton = async () => {
    await router.push({
      pathname: Routes.Authority.Root,
    });
  };

  if (loading) return <Loading />;

  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <CardLayout header={<CardHeader handleClickAddOrgsButton={handleClickButton} />}>
      <Stack
        width="100%"
        height="100%"
        bgcolor={theme => theme.palette.background.default}
        gap={2}
        p={2}
      >
        {data?.topCountries
          ?.filter(el => el.countryAlpha2)
          .map((country, index) => (
            <Grid
              container
              width="100%"
              alignItems="center"
              key={index}
              spacing={2}
            >
              <Grid
                item
                xs={1}
              >
                <Box
                  sx={{
                    width: '80%',
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    left: '10%',
                    height: 'auto',
                  }}
                >
                  {country.countryAlpha2 ? (
                    <img
                      src={`https://flagcdn.com/${country.countryAlpha2.toLowerCase()}.svg`}
                      width={30}
                      alt={country.countryName}
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={10}
              >
                <Typography
                  variant="body2"
                  mb={1}
                >
                  {country.countryName}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  color="secondary"
                  value={country.percentage}
                />
              </Grid>

              <Grid
                item
                xs={1}
              >
                <Typography variant="subtitle2">{country.percentage}%</Typography>
              </Grid>
            </Grid>
          ))}
      </Stack>
    </CardLayout>
  );
};
